import gtm from '../../../lib/gtm-library-load';
import onTrackingReady from '../common/on-tracking-ready';

// common includes

export default () => {
  onTrackingReady(() => {
    gtm();
  });
};
